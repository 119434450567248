import * as React from 'react';
import { IndicatorValue as MyType } from '@shapeable/types';
import { EntityConfig, entityCoreFormFields } from '@shapeable/core';

export const OrganisationPledge: EntityConfig<MyType> = {
  name: 'OrganisationPledge',
  type: 'standard',
  autoRoutes: ['create', 'update', 'list'],
  table: {
    fieldNames: ["metric", "value", "date", "description"]
  },
  form: {
    modal: {
      fill: true,
    },
    fieldSets: [
      { name: 'overview'  },
    ],
    fields: {
      ...entityCoreFormFields,
      metric: { type: 'linked', required: true, description: "Which {{PLEDGE_METRIC}} does this {{ORGANISATION_PLEDGE}} measure?", entityTypeName: 'PledgeMetric' },
      date: { type: 'date', description: "Is there a date which applies to this {{PLEDGE_METRIC}}?" },
      value: { type: 'text', description: "What is the specific value?" },
      description: { type: 'longText', description: "Is there a description associated with the value?" },
    },
  }

}; 