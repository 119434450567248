import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { IndicatorValue } from '@shapeable/kelp-forest-alliance-types';
import { CellLabel, EntityValue, IndicatorValueValue, useEntityUpdateModal, useLang } from '@shapeable/core';

// -------- Types -------->

export type IndicatorValueTableProps = Classable & HasChildren & {
  items: IndicatorValue[];
  onChange?: (entity: IndicatorValue) => void;
}

export const IndicatorValueTableDefaultProps: IndicatorValueTableProps = {
  items: [],
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(15)};
  `,
});

const HeadStyles = breakpoints({
  base: css`
    
  `,
});

const HeadRowStyles = breakpoints({
  base: css`
  `,
});


const BodyStyles = breakpoints({
  base: css`
    
  `,
});

const RowStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    padding-bottom: 2px;

    &:last-child {
      background: none;
    }
  `,
});

const CellStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)};
    padding-left: 0;
    padding-right: ${theme.UNIT(4)};
    width: 30%;
    white-space: nowrap;
  `,
});


const HeadCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    width: 40%;
    white-space: wrap;
    padding-right: 0;
    text-align: left;
    padding-bottom: ${theme.UNIT(2)};
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
  `,
});

const ValueHeadCellStyles = breakpoints({
  base: css`
    ${HeadCellStyles};
  `,
});

const RowHeadCellStyles = breakpoints({
  base: css`
    ${CellStyles};
  `,
});


const IndicatorStyles = breakpoints({
  base: css`
    
  `,
});

const LabelStyles = breakpoints({
  base: css`
  `,
});

const IndicatorValueStyles = breakpoints({
  base: css`
    
  `,
});






// -------- Components -------->

const My = {
  Container: styled.table<any>`${ContainerStyles}`,
    Head: styled.thead`${HeadStyles}`,
      HeadRow: styled.tr`${HeadRowStyles}`,
        HeadCell: styled.th`${HeadCellStyles}`,
        ValueHeadCell: styled.th`${ValueHeadCellStyles}`,
        
        Label: styled(CellLabel)`${LabelStyles}`,

    Body: styled.tbody`${BodyStyles}`,
      Row: styled.tr`${RowStyles}`,
        RowHeadCell: styled.th.attrs({ scope: 'row' })`${RowHeadCellStyles}`,
        Cell: styled.td`${CellStyles}`,

        Indicator: styled(EntityValue)`${IndicatorStyles}`,
        IndicatorValue: styled(IndicatorValueValue)`${IndicatorValueStyles}`,

};

export const IndicatorValueTable: React.FC<IndicatorValueTableProps> = (props) => {
  const { className, items, onChange } = props;

  const t = useLang();
  
  return (
    <My.Container cellSpacing={0} className={className}>
    <My.Head>
      <My.HeadRow>
        <My.HeadCell><My.Label>{t('Indicator')}:</My.Label></My.HeadCell>
        <My.ValueHeadCell><My.Label>{t('Ending Value')}:</My.Label></My.ValueHeadCell>
        <My.ValueHeadCell><My.Label>{t('Starting Value')}:</My.Label></My.ValueHeadCell>
      </My.HeadRow>
    </My.Head>

    <My.Body>
      {
        items.map(indicatorValue => {

          const { onClick } = useEntityUpdateModal(indicatorValue, { onSubmit: onChange });
          const { indicator, value, startValue, endValue } = indicatorValue;

          if (!indicator) {
            // indicators are required
            return null;
          }

          const { unit, precision } = indicator;
          const isSingleValue = !!value;

          return (
            <My.Row key={indicatorValue.id}>
              <My.RowHeadCell>
                <My.Indicator imageSize={20} entity={indicator} onMenuClick={onClick} name={indicator.title} variant="name" />
              </My.RowHeadCell>
              {
                isSingleValue && 
                <My.Cell>
                  <My.IndicatorValue value={value} unit={unit} />
                </My.Cell>
              }
              {
                isSingleValue && 
                <My.Cell>
                  –
                </My.Cell>
              }
              {
                !isSingleValue &&
                <My.Cell>
                  <My.IndicatorValue value={endValue} unit={unit} />
                </My.Cell>
              }

              {
                !isSingleValue &&
                <My.Cell>
                  <My.IndicatorValue value={startValue} unit={unit} />
                </My.Cell>
              }

            </My.Row>
          )
        })
      }
      <My.Row>
        
      </My.Row>
    </My.Body>
    </My.Container>
  )
};

IndicatorValueTable.defaultProps = IndicatorValueTableDefaultProps;