import * as React from 'react';
import { filter, concat, set, includes, pickBy } from 'lodash';
import { createApplication, WorkstreamTrafficControl, entityConfigs, appSectionConfigs as coreAppSectionConfigs, Icons, DataTableNameValue, DataTable, Content, IconTitle, graphql, entityConnectionTypeSetsDefault, entityCoreFormFields, EntityMultiValueList } from '@shapeable/core';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import { themes } from '@shapeable/theme';
const { Citation, IndicatorValue, FeedEntry, Video, Event, Page } = entityConfigs;

import { 
  DisturbanceType, ErrorModel, Genus, 
  Motivation, Observation, Methodology, OrganisationPledge, PledgeMetric, MethodologyType, ImpactType, Reason, RestorationProject,
  Species, TransplantAdherenceMethod, TransplantLifeStage, TransplantSource, EcologicalPrinciple
} from './config';
import { PersonDetailsLayout } from './components/person-details-layout';
import { OrganisationDetailsLayout } from './components/organisation-details-layout';
import { EconomyDetailsLayout } from './components/economy-details-layout';

const theme = themes.OCEAN_THEME;
const appSectionConfigs = coreAppSectionConfigs;
const { platform } = appSectionConfigs;

// reduce appSection configs

if (appSectionConfigs.community) {
  appSectionConfigs.community.label = 'Community';
} 

// appSectionConfigs.library.children = [
//   ...filter(appSectionConfigs.library.children, (child: any) => includes(["insights", "feed-entries"], child.id)),
//   {
//     id: 'citations',
//     type: 'list',
//     entityTypeName: 'Citation',
//     label: "Citations",
//   },
  
// ];

if (appSectionConfigs.community) {
  appSectionConfigs.community.children = filter(appSectionConfigs.community.children, (child: any) => includes(["organisations", "people"], child.id));
}

if (appSectionConfigs.participate) {
  appSectionConfigs.participate.children = [
    {
      id: 'restoration-projects',
      type: 'list',
      entityTypeName: 'RestorationProject',
      label: "Restoration Projects",
      
    },
    {
      id: 'teams',
      type: 'list',
      entityTypeName: 'Team',
    },
    {
      id: 'events',
      type: 'list',
      entityTypeName: 'Event',
    },
  ];
}

// bump
delete appSectionConfigs.teams;
delete appSectionConfigs.following;
delete appSectionConfigs.challengesGoals;
delete appSectionConfigs.places;

entityConfigs.Organisation.form.fieldSets = filter(entityConfigs.Organisation.form.fieldSets, fs => !includes(['stats', 'pledges'], fs.name));
entityConfigs.Organisation.form.fieldSets.push({ name: 'pledges' });

delete entityConfigs.Organisation.form.fields.states;

delete entityConfigs.Organisation.form.fields.angelListUrl;
delete entityConfigs.Organisation.form.fields.crunchbaseUrl;

if (Video) {
  Video.extraFields = {
    genera: { type: 'linkedMultiple', entityTypeName: 'Genus' },
    methodologies: { type: 'linkedMultiple', entityTypeName: 'Methodology' },
    economies: { type: 'linkedMultiple', entityTypeName: 'Economy' },
  };
}

if (Citation) {
  Citation.extraFields = {
    genera: { type: 'linkedMultiple', entityTypeName: 'Genus' },
    species: { type: 'linkedMultiple', entityTypeName: 'Species' },
    methodologies: { type: 'linkedMultiple', entityTypeName: 'Methodology' },
    people: { type: 'linkedMultiple', entityTypeName: 'Person' },
    economies: { type: 'linkedMultiple', entityTypeName: 'Economy' },
    organisations: { type: 'linkedMultiple', entityTypeName: 'Organisation' },
  }
}

entityConfigs.Organisation.form.fields.regions.description = 'Which region, state, province or administrative body is this organization located?';
entityConfigs.Organisation.form.fields.pledges = { type: 'linkedTable', entityTypeName: 'OrganisationPledge', description: "Record any pledges this {{ORGANISATION}} has made to help the alliance achieve their goals.", fieldSet: 'pledges' };

entityConfigs.Place.label = "Site";
entityConfigs.Place.slug = "restoration-site";
entityConfigs.Place.list.banner.content = "Record the places where Restoration Projects are undertaken.";

entityConfigs.Location.form.fieldSets = filter(entityConfigs.Organisation.form.fieldSets, fs => !includes(['stats', 'narratives'], fs.name));

entityConfigs.Place.form.fields.city.description = "What suburb or administrative areas is this site in? Be specific, what is the finest detail you would write on a postal address, avoid using large cities";
entityConfigs.Place.form.fields.regions.label = "{{REGION}}";
entityConfigs.Place.form.fields.regions.description = "Which region is this site located in, use the state, province, district, prefecture, etc, as applicable";
entityConfigs.Place.form.fields.regions.required = true;

entityConfigs.Person.form.fields.motivations = { type: 'linkedMultiple', entityTypeName: 'Motivation', description: "Which {{MOTIVATIONS}} does this {{PERSON}} pursue?", fieldSet: 'expertise' };
entityConfigs.Person.form.fields.species = { type: 'linkedMultiple', entityTypeName: 'Species', fieldSet: 'expertise', description: "Which {{SPECIES}} does this {{PERSON}} have expertise with?" };
entityConfigs.Person.form.fields.methodologies = { type: 'linkedMultiple', entityTypeName: 'Methodology', description: "Which {{METHODOLOGIES}} does this {{PERSON}} have expertise with?", fieldSet: 'expertise' };

entityConfigs.Person.form.fields.bio.description = "Describe the career of this person and how they are involved in kelp forest conservation and restoration.";

delete entityConfigs.Person.form.fields.states;


entityConfigs.Person.details.queryFields = graphql`
  ${entityConfigs.Person.details.queryFields}
  contactForRestorationProjects { ...EntityValueFields }
  motivations { ...EntityValueFields } 
  species { ...EntityValueFields }
  methodologies { ...EntityValueFields }
`;


entityConfigs.Organisation.details.queryFields = graphql`
  ${entityConfigs.Organisation.details.queryFields}
  restorationProjects { ...EntityValueFields place { ...EntityValueFields } } 
  species { ...EntityValueFields } 
  pledges { 
    ...EntityValueFields 
    id value 
    latitude longitude 
    metric { 
      id slug name title 
      unit { id shorthand name } 
    } 
  } 
  methodologies { ...EntityValueFields }
`;

entityConfigs.Economy.details.queryFields = graphql`
  ${entityConfigs.Economy.details.queryFields}
  restorationProjects { ...EntityValueFields place { ...EntityValueFields } } 
`;

entityConfigs.Organisation.filters.fields = {
  country: { entityTypeName: 'Economy' },
  regions: { entityTypeName: 'Region' },
  type: { entityTypeName: 'OrganisationType' },
  methodologies: { entityTypeName: 'Methodology' },
  species: { entityTypeName: 'Species' },
};

entityConfigs.Person.components.details = PersonDetailsLayout;
entityConfigs.Organisation.components.details = OrganisationDetailsLayout;
entityConfigs.Economy.components.details = EconomyDetailsLayout;


entityConfigs.IndicatorValue.table = {
  fieldNames: ["indicator", "endValue", "startValue"]
};

entityConfigs.IndicatorValue.form.fieldSets = [
  { 
    name: 'overview', 
    label: 'Restoration Site', 
    intro: "The values under this 'Restoration Site' tab represent the site where the restoration activity occurred, e.g. where you placed the transplants or removed the sea urchins. The values under the 'Control Site' tab represent another reference location where no restoration activity occurred – i.e. they are used to determine if the restoration activity had an effect or if changes were due to environmental variation.", 
    outro: "Each indicator value is the variable that was recorded as a result of the restoration – it may be a kelp or animal population or social factor related to restoration, e.g. (kelp/m2, jobs/year, biomass of fish/year). There may be multiple values for the observation, for example all three of the previous examples might apply to this observation." 
  },
  { name: 'dates' },
  { name: 'control-site', intro: "The values under this tab represent another reference location where no restoration activity occurred – i.e. they are used to determine if the restoration activity had an effect or if changes were due to environmental variation.",  },
];

entityConfigs.FeedEntry.list = {
  ...(entityConfigs.FeedEntry.list || {}),
  banner: {
    content: 'Read articles and news about the latest developments in kelp forest ecosystems.',
  }
}

if (FeedEntry) {
  FeedEntry.extraFields = {
    genera: { type: 'linkedMultiple', entityTypeName: 'Genus' },
    species: { type: 'linkedMultiple', entityTypeName: 'Species' },
    methodologies: { type: 'linkedMultiple', entityTypeName: 'Methodology' },
  };
}

if (Page) {
  Page.extraFields = {
    featurePosts: { type: 'linkedMultiple', entityTypeName: 'Post', label: "Featured Posts" },
    methodologies: { type: 'linkedMultiple', entityTypeName: 'Methodology', label: "Methodologies" },
    species: { type: 'linkedMultiple', entityTypeName: 'Species', label: "Species" },
    genera: { type: 'linkedMultiple', entityTypeName: 'Genus', label: "Genera" },
    economies: { type: 'linkedMultiple', entityTypeName: 'Economy', label: "Countries" },
  }
}

if (IndicatorValue) {
  IndicatorValue.extraFields = {
    
    ...entityCoreFormFields,
    indicator: { type: 'linked', required: true, description: "Which {{INDICATOR}} does this value measure?", entityTypeName: 'Indicator' },
    
    endValue: { type: 'text', label: "Final Mean Value", description: "What is the mean value of the last recorded observation?" },
    endError: { type: 'text', label: "Final Error Bound", description: "What is the error value of the last recorded observation?", recommended: false },
    endErrorModel: { type: 'linked', entityTypeName: 'ErrorModel', label: "Ending Measure Of Error", description: 'How is the error recorded?' },
    endCount: { type: 'int', label: "Final Sample Count", description: "What is the sample size of the last recorded observation, e.g. how many quadrats were used or transects were observed", recommended: false },

    startValue: { type: 'text', label: "Initial Mean Value", description: "What was the mean value before or at the start of restoration?" },
    startError: { type: 'text', label: "Initial Error Bound", description: "What is the error value for the starting mean?", recommended: false },
    startErrorModel: { type: 'linked', entityTypeName: 'ErrorModel', label: "Initial Measure Of Error", description: 'How is Error Reported for the starting mean?' },
    startCount: { type: 'int', label: "Initial Sample Count", description: "How many samples were taken for the starting mean?", recommended: false },

    startDate: { type: 'date', recommended: false, description: "When was the initial recording made?", fieldSet: 'dates' },
    endDate: { type: 'date', recommended: false, description: "When was the most recent or last recording made?", fieldSet: 'dates' },

    controlEndValue: { type: 'text', fieldSet: 'control-site', label: "Control Ending Mean Value",  description: "What was the starting mean value of all samples taken in the control site?" },
    controlEndError: { type: 'text', fieldSet: 'control-site', label: "Control Ending Error Bound", description: "What is the error value for the ending mean in the control site?"  },
    controlEndErrorModel: { type: 'linked', fieldSet: 'control-site', entityTypeName: 'ErrorModel', label: "Control Ending Measure Of Error", description: 'How is Error Reported for the control ending mean?' },
    controlEndCount: { type: 'int', fieldSet: 'control-site', label: "Control Ending Sample Count", description: "How many samples were taken for the ending mean in the control site?", },

    controlStartValue: { type: 'text', fieldSet: 'control-site', label: "Control Starting Mean Value", description: "What was the starting mean value of all samples taken in the control site?" },
    controlStartError: { type: 'text', fieldSet: 'control-site', label: "Control Starting Error Bound", description: "What is the error value for the starting mean in the control site?"  },
    controlStartErrorModel: { type: 'linked', fieldSet: 'control-site', entityTypeName: 'ErrorModel', label: "Control Starting Measure Of Error", description: 'How is Error Reported for the control starting mean?' },
    controlStartCount: { type: 'int', fieldSet: 'control-site', label: "Control Starting Sample Count", description: "How many samples were taken for the starting mean in the control site?",  },
  }
}

if (Event) {
  Event.groupName = "Participate";
  };
  
const entityConnectionTypeSets = entityConnectionTypeSetsDefault;

entityConnectionTypeSets.default.mappings.Person.push("RestorationProject");
entityConnectionTypeSets.default.mappings.RestorationProject = [
  "Person", "Organisation"
];

export const application = createApplication({
  theme,
  entityConfigs: {
    // ...entityConfigs,
    ...pickBy(entityConfigs, config => !includes([
      "ImpactStory", "Benefit", "BenefitType", "InfluencingFactor", "Challenge", 
      "OfferingType", "MaturityStage", 
      "ProjectType", 
    ], config.name)),
    DisturbanceType,
    EcologicalPrinciple,
    ErrorModel, 
    ImpactType, 
    Genus,
    Methodology, 
    MethodologyType, 
    Motivation, 
    Observation, 
    OrganisationPledge,
    PledgeMetric,
    Reason, 
    RestorationProject, 
    Species, 
    TransplantAdherenceMethod, 
    TransplantLifeStage, 
    TransplantSource, 
  },
  appSectionConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'oceans',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Ecosystems,
    entityConnectionTypeSets,
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});


/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;
