import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentNode as ContentNodeType, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Organisation } from '@shapeable/kelp-forest-alliance-types';
import { CellLabel, ContentNode, ContentTitle, useLang } from '@shapeable/core';

/* @ts-ignore */
import PLEDGE_BADGE from '../images/org-pledge-badge.webp';
import { OrganisationPledgeValue } from './organisation-pledge-value';

// -------- Types -------->

export type OrganisationPledgesAsideProps = Classable & HasChildren & {
  entity: Organisation;
}

export const OrganisationPledgesAsideDefaultProps: Omit<OrganisationPledgesAsideProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(2)};
    ${theme.H_DOT_BG_END()};

  `,
});

const HeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
    margin-left: 1px;
  `,
});

const BadgeStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
    width: 100%;
    max-width: 400px;
  `,
});

const BlurbStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const PledgesHeaderStyles = breakpoints({
  base: css`
    margin-left: 1px;
    margin-bottom: ${theme.UNIT(2)};
  `,
});


const ValueStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
    margin-right: ${theme.UNIT(3)};
    width: auto;
  `,
});

const PledgesStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,

  Header: styled(ContentTitle)`${HeaderStyles}`,
    Badge: styled.img`${BadgeStyles}`,
    Blurb: styled(ContentNode)`${BlurbStyles}`,

  Pledges: styled.div`${PledgesStyles}`,
    PledgesHeader: styled(CellLabel)`${PledgesHeaderStyles}`,
      Value: styled(OrganisationPledgeValue)`${ValueStyles}`,
};

export const OrganisationPledgesAside: React.FC<OrganisationPledgesAsideProps> = (props) => {
  const { className, entity } = props;
  const { pledges = [] } = entity;
  const t = useLang();
  const blurb: ContentNodeType = {
    text: 
`This organisation has pledged toward the  
Kelp Forest Challenge`
  };

  return (
    <My.Container className={className}>
      <My.Header>{t('Kelp Forest Challenge:')}</My.Header>
      <My.Badge src={PLEDGE_BADGE} />
      <My.Blurb entity={blurb} />

      <My.Pledges>
        <My.PledgesHeader>{t('Pledges:')}</My.PledgesHeader>
        {
          pledges.map(pledge => (
            <My.Value entity={pledge} />
          ))
        }
      </My.Pledges>
    </My.Container>
  )
};

OrganisationPledgesAside.defaultProps = OrganisationPledgesAsideDefaultProps;