import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { BannerControls, EntityDetailsBanner, EntityValue, EntityLinkValue, PersonCell, locationDMS } from '@shapeable/core';
import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';
import { filter } from 'lodash';

// -------- Types -------->

export type RestorationProjectBannerProps = Classable & HasChildren & {
  entity?: RestorationProject;
}

export const RestorationProjectBannerDefaultProps: RestorationProjectBannerProps = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const CountryStyles = breakpoints({
  base: css`
  `,
});

const ControlsStyles = breakpoints({
  base: css`
    margin-left: 0;
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const OrganisationStyles = breakpoints({
  base: css`

  `,
});

const ContactStyles = breakpoints({
  base: css`
    
  `,
});


const DMSStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(18)};
    margin-bottom: ${theme.UNIT(2)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled(EntityDetailsBanner)<any>`${ContainerStyles}`,
  DMS: styled.div`${DMSStyles}`,
  Country: styled(EntityLinkValue)`${CountryStyles}`,
    Organisation: styled(EntityLinkValue)`${OrganisationStyles}`,
    Controls: styled(BannerControls)`${ControlsStyles}`,

    Contact: styled(PersonCell)`${ContactStyles}`,
};

export const RestorationProjectBanner: React.FC<RestorationProjectBannerProps> = (props) => {
  const { className, entity } = props;
  const { country, organisation, contact, place } = entity;

  const dms = place && locationDMS(place);
  
  return (
    <My.Container 
      entity={entity}
      className={className}>
      <My.DMS>{dms}</My.DMS>
    </My.Container>
  )
};

RestorationProjectBanner.defaultProps = RestorationProjectBannerDefaultProps;