import * as React from 'react';
import { EntityConfig, entityCoreFormFields } from '@shapeable/core';
import { Observation as MyType } from '@shapeable/kelp-forest-alliance-types';
const gql = String.raw;

export const Observation: EntityConfig<MyType> = {
  name: 'Observation',
  type: 'taxonomy',
  icon: ({ icons }) => icons.Entities.Event,
  usePublishStatus: true,
  autoRoutes: ['list', 'create', 'update'],
  table: {
    fieldNames: ["actionSummary", "startDate", "endDate", "species"]
  },
  list: {
    queryFields: gql`
      description { id text }
      species { id slug name openGraph { id image { id url } } }
    `,
  },
  form: {
    modal: {
      maxWidth: "1400px",
      fill: true,
    },
    fieldSets: [
      { name: "overview", intro: 'Create or edit an individual observation which relates to a restoration action. For example this is where to enter the number of kelp or fish at a site before and after a restoration action. There may be multiple restoration observations with a restoration project, for example different years, different methods, different sub locations within a bay. Values are entered in the Indicator Values tab.' },
      { name: "taxonomies", label: "Metadata", intro: "Enter the metadata associated with the restoration observation." },
      { name: "indicator-values" },
      { name: "costs" },
      { name: "stakeholders" },
      { name: "more" },
    ],
    fields: {
      ...entityCoreFormFields,
      species: { type: 'linkedMultiple', required: true, entityTypeName: 'Species', description: "Which {{SPECIES}} are associated with this {{OBSERVATION}}?" },
      startDate: { type: 'date', fieldSet: 'overview', required: true, description: "When did the restoration activity start? For instance, when were kelps first transplanted, artificial reefs installed, or urchins removed?" },
      endDate: { type: 'date', fieldSet: 'overview', required: true, description: "When was the observation made, what day did the survey occur on" },
      recordedBy: { type: 'linkedMultiple', label: 'Recorded By', description: 'Who recorded this {{OBSERVATION}}?', entityTypeName: 'Person'  },
      areaOfRestoration: { type: 'float', label: 'Area of Restoration (Ha)', recommended: false, fieldSet: 'overview' },
      areaOfProtection: { type: 'float', label: 'Area of Protection (Ha)', recommended: false, fieldSet: 'overview' },
      successful: { type: 'boolean', recommended: false, fieldSet: 'overview', description: 'Was this observation successful? NB: {{OBSERVATIONS}} which are marked as NOT successful will NOT contribute to the restoration and protection metrics on our website.' },
      distanceToNearestKelpForestInMetres: { type: 'float', recommended: false, description: 'What was the Distance (m) to the nearest healthy kelp forest of the same species as the {{RESTORATION_PROJECT}}?' },
      actionSummary: { type: 'longText', required: true, description: 'Describe what was done and the methodologies used during this {{OBSERVATION}} of the {{RESTORATION_PROJECT}}' },
      methodologies: { required: true, type: 'linkedMultiple', entityTypeName: 'Methodology', description: "What specific restoration methods were used for this {{OBSERVATION}}" },
      transplantLifeStage: { type: 'linked', entityTypeName: 'TransplantLifeStage', fieldSet: "overview", description: "If using transplants, what life stage was transplanted. If no transplants were used" },
      transplantSource: { type: 'linked', entityTypeName: 'TransplantSource', fieldSet: "overview", description: "If transplants were used, where were they sourced from. If no transplants were used"  },

      lessonsLearned: { type: 'longText', required: true, label: 'Lessons Learned', description: "Describe any lessons you learned during the restoration process that may help future projects."},
      projectImpacts: { type: 'longText', required: true, label: 'Project Outcomes', description: "Describe any broader economic, ecological or social outcomes"},
      impactTypes: { type: 'linkedMultiple', entityTypeName: 'ImpactType' },

      disturbanceDescription: { type: 'longText', label: "Nature of Disturbance", recommended: false, fieldSet: 'overview', description: 'If a disturbance event (e.g. storm, pollution, heat wave) impacted the restoration site, describe the nature of that event and its impact below' },
      disturbanceTypes: { type: 'linkedMultiple', recommended: false, entityTypeName: 'DisturbanceType' },

      // observationNumber: { type: 'text', maxWidth: "200px", recommended: false, fieldSet: 'overview' },
      latitude: { type: 'float', recommended: false, fieldSet: 'overview', description: "Enter specific latitude/longitudes if they are different from the overall restoration project, for instance, different locations within the same bay." },
      longitude: { type: 'float', recommended: false, fieldSet: 'overview', },
      isOngoing: { type: 'boolean', recommended: false, description: "Tick if the {{RESTORATION_PROJECT}} is still continuing" },
      isProtectedArea: { type: 'boolean', fieldSet: 'overview', description: 'Tick if the {{OBSERVATION}} occurred in marine protected area, reserve, or sanctuary' },

      causeOfDecline: { type: 'longText', description: "What caused the kelp to decline or disappear? (if different from the overall {{RESTORATION_PROJECT}})", fieldSet: 'overview' },
      reasons: { type: 'linkedMultiple', entityTypeName: 'Reason' },

      objective: { type: 'longText', label: "Restoration Objective", description: "Explain the motivations and goals for conducting this observation (if different from the overall {{RESTORATION_PROJECT}} objective)", fieldSet: 'overview' },
      siteSelectionDescription: { type: 'longText', description: "Describe if there was a specific reason for choosing the location within the site for this {{OBSERVATION}}", fieldSet: 'overview' },

      citations: { type: 'linkedMultiple', entityTypeName: 'Citation', description: "Select the associated reference or citation for this observation", fieldSet: 'overview'  },
      notes: { type: 'longText', recommended: false, fieldSet: 'overview', description: "Are there any internal notes attached to this {{OBSERVATION}}? Note: this will NOT be displayed on website or platform pages." },
      
      motivations: { type: 'linkedMultiple', entityTypeName: 'Motivation', fieldSet: 'taxonomies' },
      
      kelpPriorStudy: { type: 'boolean', fieldSet: "overview", description: "Was there kelp present at the restoration site prior to the restoration intervention?"},
      // studyMitigated: { type: 'boolean', fieldSet: "overview" },

      recruitment: { type: 'boolean', fieldSet: "taxonomies", description: "Tick the box if there was a second generation of kelp at the site" },
      geneticComponent: { type: 'boolean', fieldSet: "taxonomies", description: "Tick the box if the project considered the genetic diversity of the restored kelp population" },

      ecologicalPrinciples: { fieldSet: 'taxonomies', type: 'linkedMultiple', entityTypeName: 'EcologicalPrinciple', label: 'SER Ecological Restoration Principles', description: "Which SER (Society for Ecological Restoration) Principles that were followed during this {{OBSERVATION}}"},
      
      // transplantAdherenceMethod: { type: 'linked', entityTypeName: 'TransplantAdherenceMethod', fieldSet: "taxonomies" },

      indicatorValues: { type: 'linkedTable', description: "Specify the {{INDICATOR VALUES}} for this {{OBSERVATION}}", entityTypeName: 'IndicatorValue', fieldSet: 'indicator-values' },
      
      costYear: { type: 'int', fieldSet: "costs", description: "What year was the cost reported in?" },
      costDescription: { type: 'longText', fieldSet: "costs", description: "Describe what the costs refer to, how was the money spent?" },
      
      costCurrency: { type: 'text', fieldSet: "costs", maxWidth: "100px", maxLength: 3, description: "Enter the country currency code for the costs (e.g. USD, AUD, CAD, GBP, EUR)" },
      totalCost: { type: 'float', fieldSet: "costs", description: "What was the total cost of the project?" },
      capitalCost: { type: 'float', fieldSet: "costs", description: "What was the cost of all tools used, materials used, facilities used, permanent purchases, etc?" },
      planningCost: { type: 'float', fieldSet: "costs", description: "What was the cost of hours and salary spent on planning the project?"  },
      constructionCost: { type: 'float', fieldSet: "costs", description: "What was the cost of hours and salary spent on doing the restoration?"  },
      inKindCost: { type: 'float', fieldSet: "costs", description: "What is the value of volunteer hours and donated or loaned equipment/resources?" },
      monitoringCost: { type: 'float', fieldSet: "costs", description: "What was the cost of hours and salary for follow up monitoring of restoration site?" },
      maintenanceCost: { type: 'float', fieldSet: "costs", description: "What is the cost of capital, hours and salary spent doing maintenance post restoration?" },
      
      hasPrivateFunding: { type: 'boolean', label: "Private Funding Received?", fieldSet: 'costs', description: "Tick the box if funding from a private organization was received." },
      hasUniversityFunding: { type: 'boolean', label: "University Funding Received?", fieldSet: 'costs', description: "Tick the box if funding from a university was received." },
      hasGovernmentFunding: { type: 'boolean', label: "Government Funding Received?", fieldSet: 'costs', description: "Tick the box if funding from government was received." },
      hasNGOFunding: { type: 'boolean', label: "NGO Funding Received?", fieldSet: 'costs', description: "Tick the box if funding from an NGO was received." },
      hasVolunteerFunding: { type: 'boolean', label: "Volunteer Funding Received?", fieldSet: 'costs', description: "Tick the box if fund from a volunteer organization was received." },

      isVerified: { type: 'boolean', recommended: false, fieldSet: 'admin' },
    }
  }
};