import * as React from 'react';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { ErrorModel as MyType } from '@shapeable/kelp-forest-alliance-types';
const gql = String.raw;

export const ErrorModel: EntityConfig<MyType> = {
  name: 'ErrorModel',
  label: 'Measure Of Error',
  pluralLabel: 'Measures Of Error',
  type: 'taxonomy',
  icon: ({ icons }) => icons.Entities.Target,
  autoRoutes: ['list', 'create', 'update'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
    }
  }
};