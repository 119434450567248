import * as React from 'react';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { Species as MyType } from '@shapeable/kelp-forest-alliance-types';
const gql = String.raw;

export const Genus: EntityConfig<MyType> = {
  name: 'Genus',
  type: 'taxonomy',
  icon: ({ icons }) => icons.Entities.Chapter,
  autoRoutes: ['list', 'create', 'update'],
  list: {
    resultsVariant: 'grid',
    queryFields: gql`
      description { id text }
      banner { id name slug openGraph { id title image { id url } } }
    `,
  },
  form: {
    fields: {
      ...entityStandardFormFields,
      name: { ...entityStandardFormFields.name, description: 'What is the latin genus name?' },
      banner: { type: 'linked', entityTypeName: 'Banner' },
      description: { type: 'longText' },
    }
  }
};