import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/core';
const cls = classNames('svg-icon');

// -------- Types -------->

export type SvgIconProps = Classable & HasChildren & {
  size?: number;
  color?: string;
  viewBox?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
}

export const SvgIconDefaultProps: SvgIconProps = {
  size: 16,
  viewBox: "0 0 48 48",
};

// -------- Child Component Props -------->

type ContainerProps = {
  _size: number;
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${({ _size }: ContainerProps ) => css`
      width: ${_size}px;
      height: ${_size}px;
    `}

    ${({ _color }: ContainerProps ) => _color && css`
      ${theme.FILL(_color)};
    `}

  `,
});

// -------- Components -------->

const My = {
  Container: styled.svg<ContainerProps>`${ContainerStyles}`,
};

export const SvgIcon: Shapeable.FC<SvgIconProps> = (props) => {
  const { className, size, color, viewBox, children, ...rest } = props;
  return (
    <My.Container {...rest} _color={color} _size={size} className={cls.name(className)} viewBox={viewBox}>
    {children}
    </My.Container>
  )
};

SvgIcon.cls = cls;
SvgIcon.defaultProps = SvgIconDefaultProps;