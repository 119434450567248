import React from 'react';
import { CircleIconComponent } from '@shapeable/types'
import { CircleIcon } from './circle-icon';

export const PledgeIcon: CircleIconComponent = (props) => (
  <CircleIcon {...props} viewBox="0 0 46 46">
    <path d="M23 4C33.48 4 42 12.52 42 23C42 33.48 33.48 42 23 42C12.52 42 4 33.48 4 23C4 12.52 12.52 4 23 4ZM23 0C10.3 0 0 10.3 0 23C0 35.7 10.3 46 23 46C35.7 46 46 35.7 46 23C46 10.3 35.7 0 23 0Z" />
    <path d="M32 12L20 24L14 18L11 21L20 30L35 15L32 12Z" />
    <path d="M16 32H12V34H16V32Z" />
    <path d="M22 32H18V34H22V32Z" />
    <path d="M28 32H24V34H28V32Z" />
    <path d="M34 32H30V34H34V32Z" />
  </CircleIcon>
);