import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';

import { ContentSection, EntityList, EntityListSection, EntityMultiValueList, PersonDetailsLayout as CoreLayout } from '@shapeable/core';
import { Person } from '@shapeable/kelp-forest-alliance-types';

// -------- Types -------->

export type PersonDetailsLayoutProps = Classable & HasChildren & {
  entity: Person;
}

export const PersonDetailsLayoutDefaultProps: Omit<PersonDetailsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const MultiValueListStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});


const SectionStyles = breakpoints({
  base: css`
  `,
});


const ListSectionStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled(CoreLayout)`${ContainerStyles}`,
    MultiValueList: styled(EntityMultiValueList)`${MultiValueListStyles}`,
    Section: styled(ContentSection)`${SectionStyles}`,
    ListSection: styled(EntityListSection)`${ListSectionStyles}`,
};

export const PersonDetailsLayout: React.FC<PersonDetailsLayoutProps> = (props) => {
  const { className, entity, ...rest } = props;
  const { contactForRestorationProjects = [], species = [], methodologies= [], motivations = [] } = entity;

  const hasExpertise = !!species.length || !!methodologies.length;
  const hasMainFooter = !!contactForRestorationProjects.length;

  const expertise = hasExpertise && <>
    <My.MultiValueList items={species} title="{{SPECIES}}:" />
    <My.MultiValueList items={motivations} title="{{MOTIVATIONS}}:" />
    <My.MultiValueList items={methodologies} title="{{METHODOLOGIES}}:" />
  </>;

  return (
    <My.Container
      {...rest}
      entity={entity} 
      className={className} 
      expertise={expertise}
      mainFooter={
        hasMainFooter && 
        <My.ListSection items={contactForRestorationProjects} />
      } 
    />
  )
};

PersonDetailsLayout.defaultProps = PersonDetailsLayoutDefaultProps;