import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentSection, EntityList, EntityListSection, EntityMultiValueList, OrganisationDetailsLayout as CoreLayout } from '@shapeable/core';
import { Organisation } from '@shapeable/kelp-forest-alliance-types';
import { OrganisationPledgesAside } from './organisation-pledges-aside';

// -------- Types -------->

export type OrganisationDetailsLayoutProps = Classable & HasChildren & {
  entity: Organisation;
}

export const OrganisationDetailsLayoutDefaultProps: Omit<OrganisationDetailsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->



// -------- Styles -------->

const ListStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
    .shp-entity-link {
      padding: ${theme.UNIT(4)} 0
    }
  `,
});

const SectionStyles = breakpoints({
  base: css`
  `,
});

const MultiValueListStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const ListSectionStyles = breakpoints({
  base: css`
  `,
});

const PledgesStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  List: styled(EntityList)`${ListStyles}`,
  MultiValueList: styled(EntityMultiValueList)`${MultiValueListStyles}`,
  Section: styled(ContentSection)`${SectionStyles}`,
  ListSection: styled(EntityListSection)`${ListSectionStyles}`,
  Pledges: styled(OrganisationPledgesAside)`${PledgesStyles}`,
};

export const OrganisationDetailsLayout: React.FC<OrganisationDetailsLayoutProps> = (props) => {
  const { className, entity, ...rest } = props;
  
  console.log(entity);
  
  const { restorationProjects = [], species = [], methodologies = [], pledges = [] } = entity;
  const hasMainFooter = !!restorationProjects.length;
  const hasAsideFooter = !!species.length || !!methodologies.length;
  const hasPledges = !!pledges.length;

  return (
    <CoreLayout 
      {...rest}
      entity={entity} 
      className={className}
      asideHeader={
        hasPledges && 
        <My.Pledges entity={entity} />
      }
      asideFooter={
        hasAsideFooter && 
        <My.Section title="Expertise:">
          <My.MultiValueList items={species} />
          <My.MultiValueList items={methodologies} />
        </My.Section>
      }
      mainFooter={
        hasMainFooter && 
        <My.ListSection showDescription maxItems={4} items={restorationProjects} namePath="place.name" />
      }
    />
  )
};

OrganisationDetailsLayout.defaultProps = OrganisationDetailsLayoutDefaultProps;