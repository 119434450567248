import * as React from 'react';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { ImpactType as MyType } from '@shapeable/kelp-forest-alliance-types';
const gql = String.raw;

export const ImpactType: EntityConfig<MyType> = {
  name: 'ImpactType',
  label: "Outcome Type",
  type: 'taxonomy',
  icon: ({ icons }) => icons.Entities.BenefitType,
  autoRoutes: ['list', 'create', 'update'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
    }
  }
};