import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/core';
import { SvgIcon, SvgIconProps } from './svg-icon';
const cls = classNames('circle-icon');

// -------- Types -------->

export type CircleIconProps = Classable & HasChildren & SvgIconProps & {
  fill?: string;
}

export const CircleIconDefaultProps: CircleIconProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  _fill: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${({ _fill }: ContainerProps ) => css`
      circle.${cls.sub('circle')} {
        fill: ${_fill};
      }
    `}
  `,
});

const CircleStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled(SvgIcon)<ContainerProps>`${ContainerStyles}`,
  Circle: styled.circle.attrs(cls.attr('circle'))`${CircleStyles}`
};

export const CircleIcon: Shapeable.FC<CircleIconProps> = (props) => {
  const { className, children, fill, ...rest } = props;
  return (
    <My.Container {...rest}  _fill={fill} className={cls.name(className)}>
    {
      fill && 
      <My.Circle cx="23" cy="23" r="19" />
    }
    {children}
    </My.Container>
  )
};

CircleIcon.cls = cls;
CircleIcon.defaultProps = CircleIconDefaultProps;