
export const PRIMARY_BLUE = '#03577D';
export const SECONDARY_BLUE = '#2496BD';
export const ORANGE = '#F7921D';

export const OCEAN_BLUE = '#1999B5';

export const MEDIUM_BLUE = '#2496BD';
export const BLUE = '#03577D';
export const DARK_BLUE = '#02394B';

export const AQUA_BLUE = '#1999B5';

export const BLOCK_METRIC_HEADING = '#969696';

export const VALUE_BUBBLE = '#89CDD6';

export const CONTENT_BACKGROUND = '#ECEDE8';
export const CONTENT_BACKGROUND_LIGHT = '#f4f4f1';
export const CONTENT_BACKGROUND_VERY_LIGHT = '#F7F7F5';
