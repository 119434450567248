import * as React from 'react';
import { Indicator as MyType } from '@shapeable/types';
import { EntityConfig, entityStandardFormFields, graphql } from '@shapeable/core';

export const PledgeMetric: EntityConfig<MyType> = {
  name: 'PledgeMetric',
  autoRoutes: ['create', 'update', 'list'],
  includeInMetrics: true,
  groupName: 'Data',
  table: {
    fieldNames: ["name", "title", "unit"]
  },
  list: {
    resultsVariant: 'list',
    queryFields: graphql`
      title
      unit { id name slug }
      description { id text }
    `,
  },
  form: {
    modal: {
      fill: true,
      maxWidth: "800px",
    },
    fieldSets: [
      { name: "overview" },
    ],
    fields: {
      ...entityStandardFormFields,
      title: { type: 'text', description: "Specify a title which will take precedence over the name when labelling this {{PLEDGE_METRIC}}" },
      unit: { type: 'linked', entityTypeName: "Unit" },
      description: { type: 'longText' },
      precision: { type: 'int', recommended: false, description: "For numeric indicators, what decimal precision should values be displayed at? Note: this doesn't affect the recorded precision, just how it is displayed." },
    }
  }
}; 