import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentSection, EntityList, EntityMultiValueList, EconomyDetailsLayout as CoreLayout, EntityListSection } from '@shapeable/core';
import { Economy } from '@shapeable/kelp-forest-alliance-types';

// -------- Types -------->

export type EconomyDetailsLayoutProps = Classable & HasChildren & {
  entity: Economy;
}

export const EconomyDetailsLayoutDefaultProps: Omit<EconomyDetailsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ListStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
    .shp-entity-link {
      padding: ${theme.UNIT(4)} 0
    }
  `,
});

const SectionStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)};
  `,
});

const MultiValueListStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const ListSectionStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  List: styled(EntityList)`${ListStyles}`,
  MultiValueList: styled(EntityMultiValueList)`${MultiValueListStyles}`,
  Section: styled(ContentSection)`${SectionStyles}`,
  ListSection: styled(EntityListSection)`${ListSectionStyles}`,
};

export const EconomyDetailsLayout: React.FC<EconomyDetailsLayoutProps> = (props) => {
  const { className, entity, ...rest } = props;
  
  const { restorationProjects = [], species = [] } = entity;
  const hasMainFooter = !!restorationProjects.length;
  const hasAsideFooter = !!species.length;

  return (
    <CoreLayout 
      {...rest}
      entity={entity}
      className={className}
      asideFooter={
        hasAsideFooter && 
        <My.Section title="Expertise:">
          <My.MultiValueList items={species} />
        </My.Section>
      }
      mainFooter={
        hasMainFooter && 
        <My.ListSection showDescription maxItems={4} items={restorationProjects} />
      }
    />
  )
};

EconomyDetailsLayout.defaultProps = EconomyDetailsLayoutDefaultProps;