import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, DOT_BG, theme } from '@shapeable/theme';
import { EntityMultiValueList, EntityTooltip, EntityLinkValue } from '@shapeable/core';
import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';

// -------- Types -------->

export type RestorationProjectTooltipProps = Classable & HasChildren & {
  entity?: RestorationProject;

}

export const RestorationProjectTooltipDefaultProps: RestorationProjectTooltipProps = {
  
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
    padding-top: ${theme.UNIT(3)};
    ${theme.H_DOT_BG()};
  `,
});

const SpeciesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
    padding-top: ${theme.UNIT(3)};
    ${theme.H_DOT_BG()};
  `,
});

// -------- Components -------->

const My = {
  Container: styled(EntityTooltip)<any>`${ContainerStyles}`,
    Organisation: styled(EntityLinkValue)`${OrganisationStyles}`,
    Species: styled(EntityMultiValueList)`${SpeciesStyles}`,
  };

export const RestorationProjectTooltip: React.FC<RestorationProjectTooltipProps> = (props) => {
  const { className, entity } = props;
  const { place, organisation, species = [] } = entity;
  
  const hasOrganisation = !!organisation;

  return (
    <My.Container 
      entity={entity}
      name={place && place.name}
      className={className}>
      {
        hasOrganisation &&
        <My.Organisation entity={organisation} />
      }
      {
        <My.Species items={species} />
      }

    </My.Container>
  )
};

RestorationProjectTooltip.defaultProps = RestorationProjectTooltipDefaultProps;