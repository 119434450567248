
/* tslint:disable max-line-length | no-trailing-whitespace */
import * as React from 'react';
import { Classable } from '@shapeable/types';

// @ts-ignore
import LOGO from '../images/kfa-logo-inverted.png';

export const ApplicationLogoInverted: React.FC<Classable> = ({ className }) => (
  <img className={className} src={LOGO} />
);


