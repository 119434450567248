import * as React from 'react';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { Motivation as MyType } from '@shapeable/kelp-forest-alliance-types';
const gql = String.raw;

export const Motivation: EntityConfig<MyType> = {
  name: 'Motivation',
  type: 'taxonomy',
  icon: ({ icons }) => icons.Entities.Target,
  autoRoutes: ['list', 'create', 'update', 'details'],
  details: {
    children: ({ entities }) => [
      {
        type: 'linkedMultiple',
        entityTypeName: 'RestorationProject',
        fieldName: 'restorationProjects',
      },
    ],
  },
  list: {
    resultsVariant: "grid",
  },
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
      banner: { type: 'linked', entityTypeName: 'Banner' },
      links: { type: 'linkedMultiple', entityTypeName: 'Link' },
      curator: { type: 'linked', entityTypeName: 'Person' },
      sdgs: { type: 'linkedMultiple', entityTypeName: 'Sdg' },
      restorationProjects: { type: 'linkedMultiple', entityTypeName: 'RestorationProject' },
    }
  }
};